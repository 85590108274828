import React, { useState } from "react";

const PortfolioCardRect = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      <div
        className={`relative flex flex-col justify-center overflow-hidden rounded-xl h-[260px]`}
      >
        <div className="group relative m-0 flex rounded-xl sm:mx-auto sm:max-w-lg bg-[white] drop-shadow-md transform hover:shadow-lg">
          <div
            className={`z-10 p-2 h-[250px] w-[360px] overflow-hidden rounded-xl opacity-100 transition duration-300 ease-in-out group-hover:opacity-100 dark:opacity-100`}
            onClick={openModal}
          >
            <img
              src={props.image}
              className="animate-fade-in block h-full w-[384px] rounded-md scale-100 transform object-cover object-center opacity-100 transition duration-700 lg:group-hover:scale-110"
              alt=""
            />
          </div>
          <div className="absolute bottom-0 z-20 m-0 pb-4 ps-4 transition duration-300 ease-in-out lg:group-hover:-translate-y-1 lg:group-hover:translate-x-3 lg:group-hover:scale-110">
            <h1 className="text-xl font-semibold text-white">{props.title}</h1>
            <h2 className="text-sm font-light text-gray-100 drop-shadow-xl pr-2">
              {props.subtitle}
            </h2>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative overflow-y-auto z-50">
            <div className="bg-[white] drop-shadow-md transform duration-30 hover:shadow-lg rounded-xl p-2 flex items-center">
              {" "}
              <img
                src={props.image}
                className="animate-fade-in block w-[90vh] lg:w-auto xl:h-[90vh] rounded-md scale-100 transform object-cover object-center opacity-100 transition duration-700 lg:group-hover:scale-110"
                alt="Portfolio Picture | yasith.art"
              />{" "}
              <button
                className="absolute top-4 right-4 px-8 py-2 text-sm rounded-3xl bg-red-500 text-white hover:bg-red-700 hover:text-white m-2"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PortfolioCardRect;

// const PortfolioCardRect = (props) => {
//   return (
//     <a href={props.url}>
//       <div className={`relative flex  flex-col justify-center overflow-hidden rounded-xl h-[260px]`}>
//         <div className="group relative m-0 flex  rounded-xl  sm:mx-auto sm:max-w-lg bg-[white] drop-shadow-md transform hover:shadow-lg">
//           <div className={`z-10 p-2 h-[250px] w-[360px] overflow-hidden rounded-xl opacity-100 transition duration-300 ease-in-out group-hover:opacity-100  dark:opacity-100`}>
//             <img
//               src={props.image}
//               className="animate-fade-in block h-full w-[384px] rounded-md scale-100 transform object-cover object-center opacity-100 transition duration-700 group-hover:scale-110"
//               alt=""
//             />
//             {/* <div className="absolute inset-0 bg-gradient-to-t from-[#002147] from-4% via-50% via-transparent to-transparent rounded-md transition duration-300 group-hover:scale-105 hover:rounded-xl opacity-100 m-2"></div>{" "} */}
//           </div>
//           <div className="absolute bottom-0 z-20 m-0 pb-4 ps-4 transition duration-300 ease-in-out group-hover:-translate-y-1 group-hover:translate-x-3 group-hover:scale-110">
//             <h1 className="text-xl font-semibold text-white">{props.title}</h1>
//             <h2 className="text-sm font-light text-gray-100 drop-shadow-xl pr-2">
//               {props.subtitle}
//             </h2>
//           </div>
//         </div>
//       </div>
//     </a>
//   );
// };


// export default PortfolioCardRect;
